body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

.App {
  margin: auto;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
}

.wrapper {
  background-color: #f2f2f2;
  padding-bottom: 30px;
}

.wrapper-alt {
  background-color: #fcfcfc;
  padding-top: 20px;
  padding-bottom: 20px;
}

h1 {
  font-family: "Lato", sans-serif;
  font-style: bold;
  font-size: 32px;
  font-weight: 600;
}

h2 {
  font-family: "Lato", sans-serif;
  font-style: bold;
  font-size: 28px;
  font-weight: 400;
}

h3 {
  font-family: "Lato", sans-serif;
  font-style: bold;
  font-size: 24px;
  font-weight: 400;
}

p {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #686563;
}

span {
  color: #686563;
}

.fab {
  font-size: 42px;
}

.fa-twitter-square {
  color: #1da1f2;
}

.fa-youtube {
  color: #ff0000;
}

.fa-facebook {
  color: #3b5998;
}

.btn-link {
  cursor: pointer;
}

.btn-pp {
  background-color: #4652a9;
  color: #fff;
  border-radius: 3px;
  border: solid #4652a9 4px;
  font-weight: 600;
  font-size: 14px;
}

.btn-pp:hover {
  background-color: #52a946;
  color: #fff;
  border-radius: 3px;
  border: solid #52a946 4px;
  font-weight: 600;
  font-size: 14px;
}

.userLoginForm {
  width: 400px;
  margin: auto;
}

/* Nav */
.special-announcement {
  color: #fff;
  font-size: 20px;
}

#top-nav {
  box-shadow: 0 1px 6px rgba(57, 73, 76, 0.35);
  background-color: #fcfcfc;
  color: #fff;
  min-height: 55px;
  height: 60px;
}

.nav-link-pp {
  color: #000;
}

.nav-item-pp {
  color: #686563;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

.logo {
  width: 400px;
}

#main-nav {
  background: rgba(255, 255, 255, 0.3);
  z-index: 5;
  position: relative;
  height: 90px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  min-width: 200px;
  width: 400px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  background: #fcfcfc;
  z-index: 1;
}

.dropdown-content-right {
  display: none;
  position: absolute;
  min-width: 200px;
  right: 0;
  box-shadow: 0px 8px 16px 0px rgba(60, 60, 60, 0.8);
  background: #fcfcfc;
  z-index: 1;
}

.dropdown-nav-item {
  margin-top: 5px;
  padding: 5px;
}

.dropdown-nav-item:hover {
  background: rgba(255, 255, 255, 0.8);
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropdown-content-right {
  display: block;
}

.user-nav-item {
  color: #fff;
}

.user-nav-item:hover {
  color: #fff;
  text-decoration: underline;
}

footer {
  background: #1a2940;
}

/* VIDEO OVERLAY */

.background-video {
  z-index: 3;
  top: -90px;
  position: relative;
  margin-bottom: -100px;
}

.social-media-homepage-wrapper {
  position: absolute;
  z-index: 5;
  right: 5%;
  bottom: 5%;
}

.trade-with-confidence-wrapper {
  z-index: 5;
  position: absolute;
  left: 10%;
  top: 35%;
  font-weight: 600;
  width: 80vw;
  margin: auto;
}

.trade-with-confidence {
  background: rgba(5, 10, 46, 0.8);
  padding: 20px;
  width: 680px;
  text-align: center;
  font-size: 60px;
}

.trade-with-confidence span {
  color: #fff;
}

.steps-to-success {
  margin-top: 5px;
  background: rgba(0, 0, 0, 0.8);
  padding: 10px;
  width: 680px;
  text-align: center;
  font-size: 40px;
}

.steps-to-success span {
  color: #fff;
}

.confidence-nipple-wrapper {
  text-align: center;
}

.confidence-nipple-wrapper p {
  margin-top: 30px;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
}

.confidence-nipple {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #2f8dc1;
  border: 9px solid #f08f24;
}

.confidence-nipple:hover {
  background-color: #2f8dc1;
  border: 10px solid #f08f24;
}

/* VIDEO OVERLAY 2 */

.trade-with-confidence-2 {
  background: rgba(5, 10, 46, 0.8);
  padding: 50px;
  width: 1000px;
  text-align: center;
  font-size: 80px;
}

.trade-with-confidence-2 span {
  color: #fff;
}

.steps-to-success-2 {
  margin-top: 5px;
  background: rgba(0, 0, 0, 0.8);
  padding: 10px;
  width: 1000px;
  text-align: center;
  font-size: 40px;
}

.steps-to-success-2 span {
  color: #fff;
}

/* Homepage body */

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.partners-wrapper {
  background: #fcfcfc;
  height: 200px;
  padding: 20px;
}

.partner-logo {
  height: 20px;
  margin-right: 20px;
}

.free-report-wrapper {
  padding: 100px;
}

.free-report-wrapper .col h2 {
  color: #2c4e8b;
  font-weight: 600;
  font-size: 40px;
}

.free-report-wrapper .col h3 {
  color: #2c4e8b;
  font-size: 30px;
  font-weight: 600;
}

.free-report-wrapper .col h4 {
  color: #2c4e8b;
  font-weight: 600;
  font-size: 40px;
}

.btn-free-report-dl {
  color: #fff;
  background: darkorange;
  font-size: 26px;
  border-radius: 30px;
  border: 5px solid darkorange;
  width: 100%;
}

.three-day-trader {
  width: 100%;
  height: 600px;
}

.three-day-trader h2 {
  font-size: 40px;
  color: #3b4e5f;
  font-weight: 600;
}

.three-day-trader h3 {
  font-size: 30px;
  color: #3b4e5f;
  font-weight: 600;
}

.three-day-trader-btn {
  border-radius: 30px;
  color: #fff;
  background: #0079e2;
  font-size: 20px;
  border: 10px solid #0079e2;
  width: 300px;
}

.testimonial-container {
  width: 100%;
  height: 600px;
  background: #1a2940;
  padding: 30px;
}

.testimonial-container h2 {
  text-align: center;
  color: #fff;
  font-size: 46px;
  margin-bottom: 50px;
}

.testimonial-container p {
  text-align: left;
  font-size: 30px;
  color: #a5a4a9;
}

.testimonial-container span {
  text-align: left;
  font-size: 20px;
  color: #a5a4a9;
  font-style: italic;
}

.video-slider-wrapper h2 {
  text-align: center;
  color: #fff;
  font-size: 46px;
  margin-bottom: 50px;
  text-align: center;
}

.video-slider-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 100px;
  padding-right: 100px;
  background: #2b4f8d;
}

/* Admin Dashboard */

.admin-dashboard-container {
  background: #fcfcfc;
}

.pageCounter {
  cursor: pointer;
}

.pageCounter span {
  color: blue;
}

.pageCounter span:hover {
  color: orange;
}

.edit-form-container {
  width: 500px;
}

.input-group {
  margin-bottom: 3px;
}

.input-group > .input-group-prepend {
  flex: 0 0 25%;
}
.input-group .input-group-text {
  width: 100%;
}

/* RESPONSIVITENESS */

@media (max-width: 1600px) {
  .nav-item-pp {
    font-size: 12px;
  }

  .trade-with-confidence-wrapper {
    z-index: 5;
    position: absolute;
    left: 10%;
    top: 35%;
    font-weight: 600;
    width: 80vw;
    margin: auto;
  }

  .trade-with-confidence {
    background: rgba(5, 10, 46, 0.8);
    padding: 20px;
    width: 600px;
    text-align: center;
    font-size: 50px;
  }

  .trade-with-confidence span {
    color: #fff;
  }

  .steps-to-success {
    margin-top: 5px;
    background: rgba(0, 0, 0, 0.8);
    padding: 10px;
    width: 600px;
    text-align: center;
    font-size: 30px;
  }

  .steps-to-success span {
    color: #fff;
  }

  .confidence-nipple-wrapper {
    text-align: center;
  }

  .confidence-nipple-wrapper p {
    margin-top: 30px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .confidence-nipple {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #2f8dc1;
    border: 7px solid #f08f24;
  }

  .confidence-nipple-wrapper p {
    font-size: 14px;
  }
}

@media (max-width: 1400px) {
  .trade-with-confidence {
    background: rgba(5, 10, 46, 0.8);
    padding: 20px;
    width: 500px;
    text-align: center;
    font-size: 40px;
  }

  .trade-with-confidence span {
    color: #fff;
  }

  .steps-to-success {
    margin-top: 5px;
    background: rgba(0, 0, 0, 0.8);
    padding: 10px;
    width: 500px;
    text-align: center;
    font-size: 20px;
  }

  .steps-to-success span {
    color: #fff;
  }
}

@media (max-width: 1315px) {
  .nav-item-pp {
    font-size: 10px;
  }
}

@media (max-width: 1250px) {
  .trade-with-confidence {
    background: rgba(5, 10, 46, 0.8);
    padding: 20px;
    width: 600px;
    text-align: center;
    font-size: 50px;
  }

  .steps-to-success {
    display: none !important;
  }

  .nipple-wrapper {
    display: none !important;
  }
}

/* Burger menu CSS */
/* Position and sizing of burger button */
.bm-burger-button {
  position: relative;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  margin-top: 10px;
  font-size: 20px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* QUESTIONS */

.dash-faq-container {
  border: solid 1px #cfcfcf;
  padding: 20px;
  margin: 20px 0px 20px 0px;
  overflow: hidden;
}

#dashboard-faq {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.4);
  background: #fcfcfc;
}

.modal-container {
  overflow-y: scroll;
  height: 100%;
}

.qs-container {
  overflow-y: scroll;
  height: 300px;
}

.question-edit-container {
  height: 450px;
}

.course-thumbnail {
  width: 200px;
}

.course-prev-container {
  width: 800px;
}

/* Pages */

.btn-pp-pas {
  background-color: #4652a9;
  color: #fff;
  border-radius: 3px;
  border: solid #4652a9 4px;
  font-weight: 600;
  font-size: 18px;
}

.btn-pp-pas:hover {
  background-color: #52a946;
  color: #fff;
  border-radius: 3px;
  border: solid #52a946 4px;
  font-weight: 600;
  font-size: 18px;
}

.trade-navigator-addons {
  flex-basis: 19%;
  margin: 20px 10px 0 0;
  padding: 15px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.2);

  border-left: solid 2px #4652a9;
  border-right: solid 2px #52a946;
}

#export-csv {
  margin-top: 20px;
  margin-bottom: 20px;
}

.ql-editor {
  min-height: 300px !important;
  max-height: 500px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}

.fas {
  cursor: pointer;
}

.fa-plus-square:hover {
  color: blue;
}

.fa-user-times:hover {
  color: red;
}

.fa-trash-alt:hover {
  color: red;
}

.fa-check-double {
  color: green;
}

.fa-times-circle {
  color: red;
}

.quill-pp {
  background: #fff;
}

.recipient-col-wrapper {
  width: 400px;
}

.recipient-container {
  background: #fff;
  border: 1px solid #cfcfcf;
  padding: 5px;
  font-size: 16px;
  margin-right: 5px;
  margin-top: 5px;
}

.fa-plus-square {
  color: green;
}
.fa-ban {
  color: red;
}

#recipient-wrapper {
  width: 500px;
}

